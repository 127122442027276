<template>
  <div>
    <div class="row mb-2">
      <div class="col-12 d-flex justify-content-end">
        <b-button :disabled="printing" variant="secondary" @click="exportToPDF">
          <b-spinner
            v-if="printing"
            small
            class="text-white mr-1"
            label="Loading..."
          ></b-spinner>
          <i class="mdi mdi-printer mr-1"></i> Télécharger</b-button
        >
      </div>
    </div>
    <section ref="document">
      <div class="container-fluid rounded bg-white p-5">
        <div class="d-flex">
          <p class="my-auto mr-auto">
            <i :class="$route.meta.iconClass"></i>
            <span
              style="font-size: 22px; color: #7f7f7f"
              class="font-weight-light"
            >
              Fiche comités :
            </span>
            <span style="font-size: 25px" class="font-weight-bold">{{
              COMITE.name
            }}</span>
          </p>
        </div>
        <div class="shadow" style="padding: 20px">
          <div class="row">
            <div class="col-12 d-flex flex-wrap justify-content-between">
              <p class="h4 text-secondary my-auto mr-auto">
                Informations générales
              </p>
            </div>
          </div>
          <div class="col-12 p-0">
            <hr class="bg-secondary mb-3 mt-1" />
          </div>

          <div class="row">
            <div class="col">
              <div class="row m-0 p-0">
                <div>
                  <div
                    class="
                      row
                      m-0
                      p-0
                      align-items-center
                      mb-2
                      col-sm-12 col-lg-auto
                    "
                  >
                    <div
                      style="max-width: 150px; min-width: 150px"
                      class="d-flex align-items-center p-0 m-0 mr-2"
                    >
                      <span> Libellé : </span>
                    </div>
                    <div style="flex: 1">
                      <div v-if="editMode">
                        <b-form-input
                          type="text"
                          :value="selecteddata.name"
                          v-model="newComitte.Libelle"
                          :class="{
                            'is-invalid':
                              $v.newComitte.Libelle.$error &&
                              $v.newComitte.Libelle.$dirty,
                          }"
                        >
                        </b-form-input>
                        <span
                          style="font-size: x-small"
                          class="text-danger font-weight-normal"
                          v-if="$v.newComitte.Libelle.$dirty"
                        >
                          {{
                            !$v.newComitte.Libelle.required
                              ? "Champ obligatoire"
                              : ""
                          }}
                        </span>
                      </div>

                      <span
                        class="font-weight-bold"
                        style="margin-bottom: 0px"
                        v-else
                        >{{ selecteddata.name }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="
                      row
                      m-0
                      p-0
                      align-items-center
                      mb-2
                      col-sm-12 col-lg-auto
                    "
                  >
                    <div
                      style="max-width: 150px; min-width: 150px"
                      class="d-flex align-items-center p-0 m-0 mr-2"
                    >
                      <span> Responsable : </span>
                    </div>
                    <div style="flex: 1">
                      <div v-if="editMode">
                        <v-select
                          :options="responsables"
                          placeholder="Responsable"
                          name="responsable"
                          label="text"
                          :reduce="(responsable) => responsable.value"
                          v-model="newComitte.Responsable"
                          style="padding-right: 0 !important"
                          class="font-weight-normal"
                          :class="{
                            'is-invalid form-control p-0':
                              $v.newComitte.Responsable.$error &&
                              $v.newComitte.Responsable.$dirty,
                          }"
                        >
                        </v-select>
                        <span
                          style="font-size: x-small"
                          class="text-danger font-weight-normal"
                          v-if="$v.newComitte.Responsable.$dirty"
                        >
                          {{
                            !$v.newComitte.Responsable.required
                              ? "Champ obligatoire"
                              : ""
                          }}
                        </span>
                      </div>

                      <span
                        class="font-weight-bold"
                        style="margin-bottom: 0px"
                        v-else
                        >{{
                          selecteddata.responsable.firstname +
                          " " +
                          selecteddata.responsable.lastname
                        }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="
                      row
                      m-0
                      p-0
                      align-items-center
                      mb-2
                      col-sm-12 col-lg-auto
                    "
                  >
                    <div
                      style="max-width: 150px; min-width: 150px"
                      class="d-flex align-items-center p-0 m-0 mr-2"
                    >
                      <span> Périodicité : </span>
                    </div>
                    <div style="flex: 1">
                      <div v-if="editMode">
                        <v-select
                          :options="periodicite"
                          placeholder="Périodicité"
                          name="periodicite"
                          v-model="newComitte.periodicite"
                          class="font-weight-normal"
                          style="padding-right: 0 !important"
                          :class="{
                            'is-invalid form-control p-0':
                              $v.newComitte.periodicite.$error &&
                              $v.newComitte.periodicite.$dirty,
                          }"
                        >
                        </v-select>
                        <span
                          style="font-size: x-small"
                          class="text-danger font-weight-normal"
                          v-if="$v.newComitte.periodicite.$dirty"
                        >
                          {{
                            !$v.newComitte.periodicite.required
                              ? "Champ obligatoire"
                              : ""
                          }}
                        </span>
                      </div>

                      <span
                        class="font-weight-bold"
                        style="margin-bottom: 0px"
                        v-else
                        >{{ selecteddata.periodicite }}</span
                      >
                    </div>
                  </div>
                  <div
                    class="
                      row
                      m-0
                      p-0
                      align-items-center
                      mb-2
                      col-sm-12 col-lg-auto
                    "
                  >
                    <div
                      style="max-width: 150px; min-width: 150px"
                      class="d-flex align-items-center p-0 m-0 mr-2"
                    >
                      <span> Statut : </span>
                    </div>
                    <div style="flex: 1">
                      <div v-if="editMode">
                        <v-select
                          :options="status"
                          placeholder="Statut"
                          name="Status"
                          v-model="newComitte.status"
                          style="padding-right: 0 !important"
                          class="font-weight-normal"
                          :class="{
                            'is-invalid form-control p-0':
                              $v.newComitte.status.$error &&
                              $v.newComitte.status.$dirty,
                          }"
                        >
                        </v-select>
                        <span
                          style="font-size: x-small"
                          class="text-danger font-weight-normal"
                          v-if="$v.newComitte.status.$dirty"
                        >
                          {{
                            !$v.newComitte.periodicite.required
                              ? "Champ obligatoire"
                              : ""
                          }}
                        </span>
                      </div>
                      <span
                        class="font-weight-bold"
                        style="margin-bottom: 0px"
                        v-else
                        >{{ selecteddata.status }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div style="width: 100%; flex: 1" class="ml-4">
                <span> Description : </span>
                <div class="W-100">
                  <br />
                  <textarea
                    v-if="editMode"
                    class="form-control W-100"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    style="width: 90%"
                    v-model="newComitte.description"
                  ></textarea>
                  <span
                    style="margin-bottom: 0px"
                    class="font-weight-bold"
                    v-else
                  >
                    {{ selecteddata.description }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style="position: relative; top: 25px" />
        <b-tabs class="tab-solid tab-solid-primary mt-5">
          <b-tab title="Membres permanents">
            <div class="d-flex mb-2">
              <span class="h4 text-secondary mr-auto my-auto"
                >Liste des membres
              </span>
            </div>
            <div class="col-12 p-0">
              <hr class="mt-1 mb-3 bg-secondary" />
            </div>
            <div class="row">
              <div class="col-12">
                <Members :members="members" :items="items" :print="true" />
              </div>
            </div>
          </b-tab>
        </b-tabs>

        <b-tabs class="tab-solid tab-solid-primary mt-5">
          <b-tab title="Plannings">
            <div class="d-flex mb-2">
              <span class="h4 text-secondary mr-auto my-auto"
                >Liste des plannings
              </span>
            </div>
            <div class="col-12 p-0">
              <hr class="mt-1 mb-3 bg-secondary" />
            </div>
            <div class="row">
              <div class="col-12">
                <b-table
                  :items="selecteddata.planning.sessions"
                  :fields="plannings.tableFields"
                  responsive
                  show-empty
                  bordered
                  striped
                >
                  <template v-slot:cell(inscriptions)="data">
                    <div class="text-center">
                      <div class="text-center">
                        <b-badge
                          pill
                          @click="
                            showpop(
                              tabaleitem(data.item.sessions),
                              data.item.id
                            )
                          "
                          style="
                            background-color: #adb5bd;
                            border-color: #adb5bd;
                            cursor: pointer;
                          "
                          ><span></span
                          >{{ data.value ? data.value.length : 0 }}</b-badge
                        >
                      </div>
                    </div>
                  </template>
                  <template #empty>
                    <div class="text-center text-gray">Table vide</div>
                  </template>
                  <template v-slot:cell(hourStart)="data">
                    <span>{{
                      data.item.hourStart
                        ? moment(data.item.hourStart, "HH:MM:SS").format(
                            "HH:MM"
                          )
                        : "-"
                    }}</span>
                  </template>
                  <template v-slot:cell(date)="data">
                    <b-link
                      :to="{
                        name: 'show-session',
                        params: {
                          slug: data.item.slug || 'abcde',
                          id: data.item.id,
                        },
                      }"
                      >{{
                        data.value
                          ? moment(data.value, "DD/MM/YYYY").format(
                              "DD/MM/YYYY"
                            )
                          : "-"
                      }}</b-link
                    >
                  </template>
                  <template v-slot:cell(hourEnd)="data">
                    <span>{{
                      data.item.hourEnd
                        ? moment(data.item.hourEnd, "HH:MM:SS").format("HH:MM")
                        : "-"
                    }}</span>
                  </template>
                  <template v-slot:cell(status)="data">
                    <span>{{ data.item.status }}</span>
                  </template>
                </b-table>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </section>
  </div>
</template>

<script>
import Members from "./components/Members.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import { uniq } from "lodash";
import html2pdf from "html2pdf.js";
import { minLength, required } from "vuelidate/lib/validators";

export default {
  name: "ShowInitiative",
  components: {
    Members,
    // ShowCalendar,
    // Multiselect,
  },
  data: () => ({
    printing: false,
    editMode: false,
    members: [],
    tableplannings: null,
    selecteddata: [],
    newComitte: {
      Libelle: "",
      Responsable: [],
      Periodicite: [],
      description: "",
      periodicite: [],
      status: "",
    },

    plannings: {
      tableItems: [
        {
          date: "10-07-2021",
          start_hour: "08:00",
          end_hour: "18:00",
          status: "Terminé",
          inscription_decision: 7,
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "date", label: "Date", sortable: true },
        { key: "hourStart", label: "Heure de début", sortable: true },
        { key: "hourEnd", label: "Heure de fin", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        {
          key: "inscriptions",
          label: "Inscription & Décisions",
          thStyle: { textAlign: "center" },
          sortable: true,
        },
      ],
    },
    addNewDateModal: false,
    updateNewDateModal: false,
    showCalendar: false,
    showInscriptionDecision: false,
    planificationOption: 1,
    selectedComiteItem: [{ name: "" }],
    selectedDays: [],
    weekDays: [
      { value: "monday", text: "Lundi" },
      { value: "tuesday", text: "Mardi" },
      { value: "wednesday", text: "Mercredi" },
      { value: "thursday", text: "Jeudi" },
      { value: "friday", text: "Vendredi" },
    ],
    inscriptionDecision: {
      tableItems: [
        {
          folder_type: "Initiative",
          inscription_date: "10-10-2021",
          delivery: "Dossier X",
          inscription_status: "Soumise",
          decision: "Ajourné",
          actions: ["edit", "delete"],
        },
      ],
      tableFields: [
        { key: "folder_type", label: "Type dossier", sortable: true },
        { key: "label", label: "Libellé", sortable: true },
        { key: "object_passage", label: "Objet passage", sortable: true },
        { key: "presentator", label: "Présentateur", sortable: true },
        { key: "inscription_date", label: "Date inscription", sortable: true },
        { key: "delivery", label: "Livrable", sortable: true },
        {
          key: "inscription_status",
          label: "Statut inscription",
          sortable: true,
        },
        { key: "guests", label: "Invités", sortable: true },
        { key: "decision", label: "Décision", sortable: true },
        {
          key: "decision_comment",
          label: "Commentaire décision",
          sortable: true,
        },
        { key: "actions", label: "", sortable: false },
      ],
    },
    id: "",
    newPlanning: {
      id: "",
      name: "",
      description: "",
      date: "",
      hourStart: "",
      hourEnd: "",
      status: "",
    },
    highlightedDates: [],
    inscriptions: [],
    items: [],
  }),
  validations: {
    newComitte: {
      Libelle: {
        required,
      },
      Responsable: {
        required,
      },
      periodicite: {
        required,
      },
      status: {
        required,
      },
    },
    newPlanning: {
      name: {
        required,
        minLength: minLength(5),
      },

      date: {
        required,
      },
      hourStart: {
        required,
      },
      hourEnd: {
        required,
      },
      status: {
        required,
      },
    },
  },
  methods: {
    gettime(valeur) {
      return moment(valeur, "HH:MM:SS").format("HH:MM");
    },
    exportToPDF() {
      this.printing = true;
      html2pdf()
        .set({
          margin: 1,
          filename: "comite.pdf",
          image: { type: "jpg", quality: 0.95 },
          html2canvas: { dpi: 192 },
          jsPDF: { unit: "in", format: "a1", orientation: "portrait" },
          pagebreak: { mode: "avoid-all", after: ".page" },
        })
        .from(this.$refs.document)
        .save()
        .then(() => {
          this.printing = false;
        });
    },
  },
  watch: {
    COMITE() {
      this.selecteddata = this.COMITE;
      this.selecteddata.plannings = this.selecteddata.plannings.map((plan) => ({
        ...plan,
        sessionlenght: plan.sessions[0]
          ? plan.sessions[0].inscriptions.length
          : 0,
      }));
      this.newComitte = {
        ...this.COMITE,
        Libelle: this.COMITE.name ? this.COMITE.name : "",
        Responsable: this.COMITE.responsable
          ? this.COMITE.responsable["@id"]
          : "",
        Periodicite: this.COMITE.periodicite ? this.COMITE.periodicite : "",
        description: this.COMITE.description ? this.COMITE.description : "",
      };
      this.highlightedDates = uniq(
        this.selecteddata.plannings.map((comite) =>
          moment(comite.date, "DD/MM/YYYY").format("DD/MM/YYYY")
        )
      );
      var data = [5, 4];
      var f = "";
      for (var i = 0; i < data.length; i++) {
        if (i + 1 != data.length) {
          f = f + data[i] + ",";
        } else {
          f = f + data[i];
        }
      }
    },
    MEMBERS() {
      this.members = this.MEMBERS;
      // this.members = this.MEMBERS.members.map((data) => ({
      //   member: data,
      //   organisation_attachment: data.team[0]
      //     ? data.team[0].organisation.libelle
      //     : "-",
      // }));
      this.items = this.MEMBERS.members.map((data) => ({
        member: data,
        organisation_attachment: data.team
          ? data.team.organisation
            ? data.team.organisation.libelle
            : "-"
          : "-",
      }));
    },
    INSCRIPTION() {
      this.inscriptions = this.INSCRIPTION.plannings.map((inscription) =>
        inscription.sessions.map((insc, inscIndex) =>
          insc.inscriptions.map((dossier) => ({
            ...dossier,
            inscription: insc.inscriptions[inscIndex],
          }))
        )
      );
    },
  },

  created() {
    this.$store.dispatch("comite/fetchComite", this.$route.params.id);
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchComiteStatus");
    this.$store.dispatch("status/fetchPeriodicite");
    this.$store
      .dispatch("comite/fetchMembers", this.$route.params.id)
      .then(() => {
        this.$store
          .dispatch("planning/fetchinscription", this.MEMBERS.id)
          .then(() => (this.loading = false));
      });
  },

  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters("comite", ["COMITE"]),
    ...mapGetters("manageComite", ["INSCRIPTIONS"]),
    ...mapGetters("comite", ["MEMBERS"]),
    ...mapGetters("status", ["COMITE_STATUS"]),
    ...mapGetters("status", ["PERIODICITE"]),
    ...mapGetters("planning", ["INSCRIPTION"]),
    ...mapGetters("planning", ["PLANNING"]),

    rows() {
      return this.selecteddata.plannings.length;
    },
    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    status() {
      return this.COMITE_STATUS;
    },
    periodicite() {
      return this.PERIODICITE;
    },

    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: `/api/referentiel/organisations/${organisation.id}`,
        text: organisation.libelle,
      }));
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
#days-checkboxes > div > label > span {
  margin: 4px 0 0 0 !important;
  display: block !important;
}
@media (min-width: 600px) {
  .align-btn-table {
    position: relative;
    left: -95px;
  }
}

.sub-modal[data-v-71a1a1eb] {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  min-width: 80%;
  max-width: 80%;
  min-height: 95%;
  max-height: 95%;
  position: fixed !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: auto;
  height: auto;
}
.custom-select.vs__search:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.4;
  font-size: 1em;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  margin: 4px 0 0 0;
  padding: 0 7px;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  z-index: 1;
}
</style>
